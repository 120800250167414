import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./e-commerce.scss";

import { InView } from "react-intersection-observer";

import Layout from "../../components/layout/layout";
import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";
import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";

import OpenCart from "../../images/technologies/logo_opencart.inline.svg";
import Magento from "../../images/technologies/logo_magento.inline.svg";
import Woocommerce from "../../images/technologies/logo_woocommerce.inline.svg";
import PrestaShop from "../../images/technologies/logo_prestashop.inline.svg";
import ScrollButton from "../../components/scroll-button/scroll-button";

const SklepyInternetowePage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie",
      to: "/",
    },
    {
      name: "Umowa",
      to: "/",
    },
    {
      name: "Projekt graficzny",
      to: "/",
    },
    {
      name: "Programowanie",
      to: "/",
    },
    {
      name: "Uzupełnienie produktów w&nbsp;sklepie",
      to: "/",
    },
    {
      name: "Wydanie, instalacja",
      to: "/",
    },
    {
      name: "Opieka i&nbsp;administracja",
      to: "/",
    },
  ];

  const data = useStaticQuery(
    graphql`
      {
        project: file(
          relativePath: { eq: "sklepy-internetowe/yummypix-rwd.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rwd: file(relativePath: { eq: "sklepy-internetowe/rwd.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        wallers_design: file(relativePath: { eq: "sklepy-internetowe/wallers-design.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["wallers", "yummypix", "zaklady-miesne-kozlowski"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO
        title="Sklepy internetowe"
        description="Zacznij sprzedawać w&nbsp;internecie. Zaprojektujemy i&nbsp;stworzymy dla Ciebie profesjonalny sklep internetowy. •E-commerce ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Sklepy internetowe i&nbsp;platformy sprzedażowe</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Projektowanie i&nbsp;tworzenie sklepów internetowych e-commerce
            </h2>
          </div>
          <h4>
            W&nbsp;naszych realizacjach, klienci docenili: dedykowane moduły do
            platform e-commerce, oryginalne szablony graficzne sklepów,
            intuicyjność interfejsów (UI i&nbsp;UX), skuteczne pozycjonowanie
            SEO, zwiększenie ilości klientów, realizacje w&nbsp;krótkim
            terminie.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>

      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Dlaczego warto zaprojektować sklep internetowy z&nbsp;naszą agencją"
        />
      </Section>

      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.project.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="01 Skalowalność"
              headerText="Sklep internetowy to doskonały pomysł na biznes"
            />
            <p>
              Ponad połowa polaków aktywnie korzysta z&nbsp;zakupów
              w&nbsp;internecie. Z&nbsp;roku na rok, wartość rynku E-commerce
              zwiększa się. Przybywa nowych klientów a&nbsp;przez sytuację
              związaną z&nbsp;COVID-19, popularne stało się kupowanie nie tylko
              produktów takich jak elektronika czy książki, ale również odzież
              i&nbsp;obuwie...
            </p>
            <p>
              Klienci doceniają nie tylko możliwość zakupów 24h/dobę, we
              wszystkie dni tygodnia, ale również wygodę i&nbsp;zdecydowanie
              niższą cenę sprzedawanych produktów. Warto wykorzystać potencjał
              sprzedaży online.{" "}
              <strong>
                Posiadanie sklepu internetowego po prostu się opłaca!
              </strong>
            </p>
            <p>
              <strong>
                Chcesz rozpocząć sprzedaż w&nbsp;swoim sklepie internetowym?
                Gwarantujemy, że:
              </strong>
            </p>
            <ul>
              <li>przygotowany sklep internetowy będzie skalowalny,</li>
              <li>
                umożliwimy dodawanie nieograniczonej ilości produktów
                i&nbsp;parametrów,
              </li>
              <li>
                wraz z&nbsp;rozwojem sklepu, umożliwimy jego optymalizację.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>

      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="02 Niezawodność"
              headerText="UI, UX, SEO, Design"
            />
            <p>
              Projektujemy i&nbsp;tworzymy sklepy internetowe bez abonamentu,
              platformy B2B, sklepy dropshipping oraz sklepy dedykowane. Sklep
              internetowy lub katalogi produktów, oparte na bazie danych.
            </p>
            <p>
              Wiemy, na które elementy warto zwrócić uwagę tworząc
              i&nbsp;rozwijając własny sklep internetowy. Potrafimy
              zaprojektować dobrze działającą wyszukiwarkę produktów, kategorie,
              filtry, auto podpowiedzi, płatności online... Biegle,
              wykorzystujemy te elementy aby usprawnić zakupy.
            </p>
            <p>
              Dobrze zaprojektowany UX, UI to już na starcie połowa sukcesu.
              Kolejnym elementem strategi, który zdecydowanie przekłada się na
              wyniki sprzedaży jest pozycjonowanie SEO sklepu internetowego.
              Optymalizacja platformy e-commerce pod kątem przyszłego
              pozycjonowania pozwala osiągnąć wysokie wyniki
              w&nbsp;wyszukiwarkach.
            </p>
            <p>
              <strong>Nasza firma kładzie duży nacisk na:</strong>
            </p>
            <ul>
              <li>pozytywne doświadczenia zakupowe klientów sklepu,</li>
              <li>oryginalny, funkcjonalny layout sklepu,</li>
              <li>prawidłowe pozycjonowanie produktów,</li>
              <li>dostępność na urządzeniach mobilnych.</li>
            </ul>
          </div>
          <div data-sticky className="container">
            <Img
              fluid={data.wallers_design.childImageSharp.fluid}
              className="image-test"
              alt="Sklep internetowy Wallers wyświetlony na iMac"
              title="Grafika przedstawia zaprojektowane mockupu sklepu internetowego Wallers"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <div className="container r :c-12 :w offer-technology-wrapper">
              <div className="item">
                <Woocommerce />
              </div>
              <div className="item">
                <Magento />
              </div>
              <div className="item">
                <PrestaShop />
              </div>
              <div className="item">
                <OpenCart />
              </div>
            </div>
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="03 Technologie"
              headerText="Pracujemy na wszystkich popularnych platformach e-commerce"
            />
            <p>
              Do realizacji usług związanych z&nbsp;przygotowaniem sklepów
              internetowych, wykorzystujemy większość najpopularniejszych
              skryptów i&nbsp;wtyczek dostępnych w&nbsp;branży. Użycie
              rozbudowanych systemów takich jak: <strong>PrestaShop</strong>,
              <strong>Magento</strong>, <strong>OpenCar</strong>,{" "}
              <strong>Woo-commerce</strong>, rozwijanych przez dużą społeczność
              programistów, umożliwia stworzenie zaawansowanej platformy
              e-commerce w&nbsp;optymalnym czasie i&nbsp;racjonalnych kosztach.
            </p>
            <p>
              Wybór niezawodnego oprogramowania, dopasowanego do skali
              prowadzonego biznesu (mały lub rozbudowany sklep internetowy),
              pozwala na prowadzenie analiz, statysty, zarządzanie produktami
              oraz wyklucza inne ograniczenia funkcjonalne.
            </p>
            <p>
              <strong>Naszym klientom </strong>
            </p>
            <ul>
              <li>
                doradzimy w&nbsp;wyborze najlepszego oprogramowania,
                uwzględniając skalę potrzeb,
              </li>
              <li>
                zagwarantujemy prawidłowe wdrożenie sklepu, oraz niezawodny
                system e-commerce,
              </li>
              <li>
                przeprowadzimy szkolenie oraz przekażemy dokumentację projektu.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="04 Rozumiemy potrzeby..."
              headerText="E-sklepy przyjazne właścicielom"
            />
            <p>
              Wiemy jak ważna jest codzienna obsługa. Dobrze zaprojektowany
              panel administracyjny powinien posiadać funkcje, które
              zdecydowanie usprawniają codzienną pracę. Zadania takie jak: auto
              odpowiedzi, automatyczne płatności internetowe, faktury,
              generowanie list kurierskich przekładają się na szybkość
              i&nbsp;jakość realizacji zamówienia.
            </p>
            <p>
              Potrafimy rozwiązać najczęstsze problemy właścicieli e-sklepów.
              Usprawniamy obsługę zamówień, zarządzanie produktami i&nbsp;ofertą
              oraz dbamy o&nbsp;informacje zbierane w formie raportów
              i&nbsp;statystyk. Realizujemy też "MultiStory" - jeden panel
              administracyjny do zarządzania kilkoma sklepami internetowymi.
            </p>
            <p>
              <strong>
                Wprowadzimy wszystkie usprawnienia których potrzebujesz:
              </strong>
            </p>
            <ul>
              <li>
                transparentność zarządzania zamówieniami, raporty
                i&nbsp;statystyki,
              </li>
              <li>stany magazynowe, uaktualniane automatycznie,</li>
              <li>dedykowane usprawnienia w&nbsp;panelu administracyjnym.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.wallers_design.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="05 Administracja"
              headerText="Wsparcie i&nbsp;opieka po wdrożeniowa"
            />
            <p>
              Do komfortowego prowadzenia sprzedaży w&nbsp;internecie potrzebne
              jest sprawne działanie platformy i&nbsp;szybka reakcja na zmiany.
              Każdy przestój w&nbsp;dostępności, zawsze wiąże się z&nbsp;utratą
              zaufania klientów.
            </p>
            <p>
              Wybierając wykonawcę sklepu internetowego, warto wziąć pod uwagę
              odpowiedni poziom wsparcia technicznego. Doświadczona agencja
              gwarantuje poprawne działanie sklepu oraz szybkie wprowadzanie
              zmian związanych z&nbsp;działaniem platformy sprzedażowej.
            </p>
            <p>
              <strong>Gwarantujemy, że:</strong>
            </p>
            <ul>
              <li>
                usuniemy awarię lub wprowadzimy zmiany w&nbsp;krótkim terminie
                od zgłoszenia,
              </li>
              <li>zadbamy o&nbsp;aktualizację platformy.</li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <div className="align-center">
          <Button color="primary" type="inversed" to="/kontakt" language="pl">
            Darmowa wycena sklepu internetowego
          </Button>
        </div>
      </Section>

      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Sklepy internetowe"
          headerText="Kilka naszych realizacji"
        />
        <PortfolioList items={items}></PortfolioList>
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </Section>

      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Profity sklepów</br>internetowych"
          spanColor="#484848"
          headerText="Po co tworzyć profesjonalny sklep internetowy"
          color="#50817b"
        />
        <BoxList
          boxItems={[
            {
              title: `Zwiększenie sprzedaży`,
              span: "01",
              description:
                "Sklep internetowy umożliwia dotarcie do szerokiego grona klientów. Wystarczy odpowiednia promocja sklepu aby zdecydowanie zwiększyć zyski ze sprzedaży produktów.",
            },
            {
              title: `Optymalizacja kosztów`,
              span: "02",
              description:
                "Prowadzenie własnego E-biznesu nie wymaga dużych nakładów finansowych. Utrzymanie działalności kosztuje zdecydowanie mniej niż prowadzenie sklepu stacjonarnego.",
            },
            {
              title: `Dropshipping - sklep bez magazynu`,
              span: "03",
              description:
                "Możliwość wykorzystania modelu biznesowego, który pozwala ograniczyć rolę sklepu do zbierania zamówień i&nbsp;przekazywania ich do hurtowni. To na nią przeniesiony jest cały proces wysyłki towarów do klientów a&nbsp;właściciel sklepu internetowego zarabia na marży nałożonej na produkty. ",
            },
          ]}
        />
      </Section>

      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <HeaderBig
              tag="h2"
              span="Etapy współpracy"
              headerText="Ralizację sklepu internetowego, przeprowadzimy w&nbsp;kilku prostych krokach"
            />
          </div>
          <ul className="experience count container">
            {experienceItems.map((item, index) => {
              return (
                <InView key={index} rootMargin="-49% 0px -49% 0px">
                  {({ inView, ref, entry }) => (
                    <li ref={ref} className={inView ? "active" : ""}>
                      <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                    </li>
                  )}
                </InView>
              );
            })}
          </ul>
        </StickyContainer>
      </Section>
      <Section className="container align-center">
        <Button color="primary" type="default" to="/oferta" language="pl">
          Wróć na stronę oferty
        </Button>
      </Section>
      <CallFooter />
    </>
  );
};

export default SklepyInternetowePage;
